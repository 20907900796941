export function LoadingPagePlaceholder() {
  return (
    <div className="h-screen w-screen flex items-center justify-center dark:bg-gray-800">
      <LoadingDots />
    </div>
  )
}

export const LoadingDots = () => {
  return (
    <div className="loading-dots-animation flex items-center justify-center space-x-1 font-bold dark:text-white">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  )
}
