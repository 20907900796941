import { User } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'

export const initLogger = (clientToken: string, site: string) => {
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    trackSessionAcrossSubdomains: true,
    env: process.env.ENVIROMENT ?? 'development',
    service: 'briefcase-web',
    version: process.env.RENDER_GIT_COMMIT ?? 'unknown',
    useSecureSessionCookie: true,
    usePartitionedCrossSiteSessionCookie: true,
  })
}

export const addUserToLogger = (user: User) => {
  datadogLogs.setUser(user)
}

export default datadogLogs.logger
