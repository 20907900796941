/**
 * REVIEW how does this affect page performance?
 */
export enum Viewport {
  // Use numbers so we can compare
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
  XXL = 5,
}

function getViewport(): Viewport {
  // REVIEW what about SSR where `window` isn't defined?
  const width = window.innerWidth

  // https://tailwindcss.com/docs/responsive-design
  return width > 1536
    ? Viewport.XXL
    : width > 1280
      ? Viewport.XL
      : width > 1024
        ? Viewport.LG
        : width > 768
          ? Viewport.MD
          : width > 640
            ? Viewport.SM
            : Viewport.XS
}

const ViewportContext = React.createContext<Viewport>(getViewport())

export const ViewportProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [viewport, setViewport] = React.useState(getViewport())

  React.useEffect(() => {
    function handleResize() {
      setViewport(getViewport())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = () => React.useContext(ViewportContext)
