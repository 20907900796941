import { Suspense, useEffect } from 'react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import * as Sentry from '@sentry/react'
import { RelayEnvironmentProvider } from 'react-relay'
import { ToastContainer } from 'react-toastify'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'

import { AuthProvider, getAccessToken, useUser } from './auth'
import { addUserToLogger, initLogger } from './browserLogger'
import { LoadingPagePlaceholder } from './components/LoadingPagePlaceholder'
import { ViewportProvider } from './responsive'

const fetchFunction = async (params, variables) => {
  const token = await getAccessToken()
  const authHeaders: HeadersInit = token
    ? {
        authorization: `Bearer ${token.token}`,
        'auth-provider': token.provider,
      }
    : {}

  const result = await fetch(RWJS_API_GRAPHQL_URL, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  })
  return await result.json()
}

function createEnvironment() {
  const network = Network.create(fetchFunction)
  const store = new Store(new RecordSource())
  return new Environment({ store, network })
}

const environment = createEnvironment()

function SetUserLoggingContext({ children }: { children: React.ReactNode }) {
  const user = useUser()
  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      addUserToLogger({
        id: user.id,
        email: user.email,
        name: user.displayName,
      })
    }
  }, [user])
  return <>{children}</>
}

if (
  process.env.DD_SITE &&
  process.env.DATADOG_CLIENT_TOKEN &&
  process.env.NODE_ENV === 'production'
) {
  initLogger(process.env.DATADOG_CLIENT_TOKEN, process.env.DD_SITE)
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2a9cc00b284e7cf77f84843db4d6bdd2@o4507231967379456.ingest.de.sentry.io/4507248164864080',
    environment: process.env.ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 0.0 as we only want to use Sentry for
    // error monitoring (not for performance monitoring)
    tracesSampleRate: 0.0,
  })
}

const App = () => {
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <Suspense fallback={<LoadingPagePlaceholder />}>
            <RelayEnvironmentProvider environment={environment}>
              <SetUserLoggingContext>
                <ViewportProvider>
                  <Routes />
                  <ToastContainer
                    position="top-right"
                    hideProgressBar={true}
                    closeButton={true}
                    theme="dark"
                    autoClose={false}
                  />
                </ViewportProvider>
              </SetUserLoggingContext>
            </RelayEnvironmentProvider>
          </Suspense>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
